<template>
    <div class="screen">
        <Nav/>
        <div class="wrapper screen-content">
            <div class="content card">
                <template v-if="!GlobalState.user">
                    <h1>{{ isLogin ? "Log in" : "Sign up" }}</h1>
                    <input type="text" name="username" v-model="username" class="theme-input" placeholder="Username">
                    <input type="password" name="password" v-model="password" class="theme-input" placeholder="Password">
                    <button v-on:click="process()" class="theme-button">{{ isLogin ? "Log in" : "Sign up" }}</button>
                    <p class="toggle" v-on:click="isLogin = !isLogin">{{ isLogin ? "Sign up" : "Log in" }}</p>
                </template>
            <template v-if="GlobalState.user">
                <h1>Welcome back <span class="username">{{ GlobalState.user.username }}</span>!</h1>
                <router-link to="/" class="theme-link">Home</router-link>
            </template>
            </div>
        </div>
        
    </div>
</template>
<script>
import { login, register } from '../api';
import { GlobalState } from '../GlobalState';


export default {
    data(){
        return {
            GlobalState,
            username:"",
            password:"",
            isLogin:true,
        }
    },
    methods: {
        process(){
            if (this.isLogin){
                login(this.username,this.password);
            } else {
                register(this.username, this.password).then(()=>{
                    login(this.username,this.password);
                }).catch(()=>{
                    alert("Could not register");
                })
            }
        }
    },
}
</script>
<style scoped>
.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
}
.theme-button, .theme-input {
    width: 100%;
}
.toggle {
    cursor: default;
    font-size: 14px;
}
.theme-input {
    margin-bottom: 10px;
}
.username {
    color: #9792E3;
}
</style>